export const APISettings = {
  token: "",
  headers: new Headers({
    Accept: "application/json",
  }),
  baseURL: "https://api.lafamilia.rocks/v1",
  minecraftAPIURL:
    "https://launchermeta.mojang.com/mc/game/version_manifest.json",
  steamAPIURL:
    "https://api.steampowered.com/IGameServersService/GetServerList/v1/",
};
